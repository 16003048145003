import React, { useState, useEffect, useRef } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import StocksRow from "./stockRow";

const apiGatewayURL =
  "https://76zrx5awl4.execute-api.us-east-1.amazonaws.com/picus84/portfolio/transactions";

function calculatePortfolio(ticker, lots, currentPrice) {
  const amount = lots.reduce((accumulator, object) => {
    return accumulator + object.amount;
  }, 0);
  const totalCost = lots.reduce((accumulator, object) => {
    return accumulator + object.price * object.amount;
  }, 0);
  const averagePrice = totalCost / amount;
  const pnl = averagePrice * amount;
  return {
    ticker,
    amount,
    averagePrice,
    currentPrice,
    pnl,
    lots,
  };
}

function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export default function StocksTable() {
  const [transactions, setTransactions] = useState([]);
  const transactionsRef = useRef(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await fetch(apiGatewayURL + "?userID=1234");
      const data = await response.json();

      const groupedTransactions = groupBy(data, "ticker");

      const newTransactions = [];
      for (const [key, value] of Object.entries(groupedTransactions)) {
        const options = value.map((row) => {
          // This function defines the "mapping behaviour". name and title
          // data from each "row" from your columns array is mapped to a
          // corresponding item in the new "options" array
          return {
            date: row.date_id,
            amount: Number(row.amount),
            price: Number(row.price),
          };
        });

        newTransactions.push(calculatePortfolio(key, options, 120));
      }
      if (transactionsRef.current === null) {
        transactionsRef.current = newTransactions;
        setTransactions(newTransactions);
      } else {
        transactionsRef.current = newTransactions;
      }
    };

    fetchTransactions();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Stock ticker</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Average price</TableCell>
            <TableCell>Current price</TableCell>
            <TableCell>P/L</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row) => (
            <StocksRow key={row.ticker} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
