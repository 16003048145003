import "./App.css";
import Top from "./components/appBar";
import StocksTable from "./components/stocksTable";
import Footer from "./components/footer";

function App() {
  return (
    <div>
      <Top />
      <StocksTable />
      <Footer />
    </div>
  );
}

export default App;
