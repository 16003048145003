import PropTypes from "prop-types";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
// import { w3cwebsocket as W3CWebSocket } from "websocket";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveIcon from '@mui/icons-material/Remove';

// const client = new W3CWebSocket("ws://127.0.0.1:8080");

export default function StocksRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const handleDeleteTransaction = (transaction) => {
    console.log("Delete transaction: ", transaction);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ticker}
        </TableCell>
        <TableCell>{row.amount.toFixed()}</TableCell>
        <TableCell>{row.averagePrice.toFixed(2)}</TableCell>
        <TableCell>{row.currentPrice.toFixed(2)}</TableCell>
        <TableCell>{row.pnl.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Lots
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lots.map((lot) => (
                    <TableRow key={lot.date}>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleDeleteTransaction("Add row ID here");
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formateDate(lot.date.split("-")[0])}
                      </TableCell>
                      <TableCell>{lot.amount}</TableCell>
                      <TableCell>{lot.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

StocksRow.propTypes = {
  row: PropTypes.shape({
    ticker: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    averagePrice: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    pnl: PropTypes.number.isRequired,
    lots: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function formateDate(epochTime) {
  const date = new Date(Number(epochTime)); // create a Date object from the epoch time
  const year = date.getFullYear(); // get the year (e.g. 2023)
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // get the month (0-11) and pad with leading zero
  const day = date.getDate().toString().padStart(2, "0"); // get the day of the month and pad with leading zero
  const formattedDate = `${year}-${month}-${day}`; // create a formatted date string
  return formattedDate;
}
