import * as React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircle from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const apiGatewayURL = "https://76zrx5awl4.execute-api.us-east-1.amazonaws.com/picus84/portfolio/transactions"

export default function NewTransaction() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState({
    ticker: "",
    amount: "",
    price: "",
    date: formatDate(),
  });

  const handleClickOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleClickCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDialogChange = (event) => {
    setTransaction({
      ...transaction,
      [event.target.id]: event.target.value,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmitTransaction = (event) => {
    event.preventDefault();
    console.log("Save transaction: ", transaction);
    saveTransaction(transaction);
    setDialogOpen(false);
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClickOpenDialog}
        color="inherit"
      >
        <AddCircle />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={handleClickCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Record a transaction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details of the transaction.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="ticker"
            label="Stock ticker"
            type="text"
            fullWidth
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            id="amount"
            label="Amount"
            type="number"
            fullWidth
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            id="date"
            label="Date"
            type="date"
            defaultValue={formatDate()}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={handleDialogChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitTransaction} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function formatDate() {
  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
}

function saveTransaction(transaction) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      userID: 1234,
      date_id: new Date(transaction.date).getTime(),
      ticker: transaction.ticker,
      amount: transaction.amount,
      price: transaction.price,
    }),
  };

  fetch(apiGatewayURL, requestOptions)
    .then((response) => response.json())
    // .then((data) => setData(data))
    .catch((error) => console.log(error));
}
