import * as React from "react";
import { AppBar, CssBaseline, Toolbar, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import NewTransaction from "./newTransaction";

const theme = createTheme();

export default function Top() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <TrendingUpIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Portfolio manager
          </Typography>
          <NewTransaction />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
